import React from "react";
import { Link, graphql } from "gatsby";

// import Custom Components
import PageHeader from "../components/common/page-header";
import Breadcrumb from "../components/common/breadcrumb";
import MainLayout from "../layouts/MainLayout";
import Seo from "../components/common/SEO";
import slugify from "slugify";
import { GatsbyImage } from "gatsby-plugin-image";

function Listing(props) {
  const blogs = props.data.blogs.nodes;

  let options = {
    year: "numeric",
    month: "short",
    day: "2-digit",
    timeZone: "UTC",
  };
  const { metadata } = props.pageContext;
  const { title, seoMetaData } = metadata?.pageSettings?.blog;
  const seoTitle = metadata?.pageSettings?.blog?.title || "Blog Page";

  return (
    <MainLayout>
      <Seo title={title} page={{ title: seoTitle, seoMetaData }} />
      <h1 className="d-none">Molla React eCommerce Template - Blog Listing</h1>

      <div className="main">
        <PageHeader title={title || "Blog"} />
        <Breadcrumb title={title || "Blog"} />

        <div className="page-content">
          <div className="container">
            <div className="row d-flex align-items-center justify-content-center">
              <div className="col-lg-9">
                {blogs.length === 0 ? (
                  <div className="text-center">
                    <p>No posts for the moment</p>
                  </div>
                ) : (
                  <article className="entry entry-list">
                    {blogs.map((post, index) => (
                      <div className="row mb-2" key={`blog-${index}`}>
                        <>
                          <div className={`col-md-5`}>
                            <figure
                              aria-labelledby={`blog-${index}`}
                              className="entry-media"
                            >
                              <Link
                                // to={`/singlePost`}
                                // state={{ post: post }}
                                to={`/${
                                  metadata?.pageSettings?.blog?.seoMetaData
                                    ?.slug || "blog"
                                }/${
                                  post.slug || slugify(post.title).toLowerCase()
                                }/`}
                                aria-label="blog image"
                              >
                                <>
                                  <div className="lazy-overlay bg-3"></div>
                                  <GatsbyImage
                                    image={
                                      post.featuredImage?.childImageSharp
                                        ?.gatsbyImageData
                                    }
                                    alt={post.excerpt}
                                  />
                                </>
                              </Link>
                            </figure>
                          </div>
                          <div className={`col-md-7`}>
                            <div className="entry-body">
                              <div className="entry-meta">
                                <span style={{color: 'black'}} className="entry-author">
                                  by{" "}
                                  <Link to="#">
                                    {post.author || "anonymous"}
                                  </Link>
                                </span>
                                <span style={{color: 'black'}} className="meta-separator">|</span>
                                <Link style={{color: 'black'}} to="#">
                                  {new Date(post.createdAt).toLocaleDateString(
                                    "en-US",
                                    options
                                  )}
                                </Link>
                              </div>

                              <h2 className="entry-title">{post.title}</h2>

                              <div
                                className="entry-content"
                                id={`blog-${index}`}
                              >
                                {post.excerpt}
                              </div>
                              <div>
                                <Link
                                  // to={`/singlePost`}
                                  // state={{ post: post }}
                                  to={`/${
                                    metadata?.pageSettings?.blog?.seoMetaData
                                      ?.slug || "blog"
                                  }/${
                                    post.slug ||
                                    slugify(post.title).toLowerCase()
                                  }/`}
                                  className="read-more"
                                >
                                  Continue Reading
                                </Link>
                              </div>
                            </div>
                          </div>
                        </>
                      </div>
                    ))}
                  </article>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default React.memo(Listing);
export const query = graphql`
  query MyBlogPosts {
    blogs: allPages(filter: { type: { eq: "blog" } }) {
      nodes {
        title
        content
        slug
        author
        excerpt
        createdAt
        featuredImage {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
